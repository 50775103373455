import React, { Component } from 'react';
import { LayersControl, MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';

const icon = new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] });

const ControlComponent = ({ onChangeCoordinate, disabled }) => {
    const map = useMapEvents({
        locationfound: ({ latlng }) => {
            map.setView(latlng, map.getZoom());
            if (onChangeCoordinate) onChangeCoordinate({ latitude: latlng.lat, longitude: latlng.lng });
        },
        dblclick: ({ latlng }) => {
            map.setView(latlng, map.getZoom());
            if (!disabled && onChangeCoordinate) onChangeCoordinate({ latitude: latlng.lat, longitude: latlng.lng });
        }
    });
    return null;
}

const CenterMapButton = ({ coordinate }) => {
    const map = useMap();

    const handleClick = event => {
        event.stopPropagation();
        const { latitude, longitude } = coordinate ?? {};
        if (latitude && longitude) {
            map.setView([coordinate.latitude ?? 0, coordinate.longitude ?? 0], map.getZoom());
        }
    };

    return (
        <Button variant='primary'
            style={{
                position: "absolute",
                bottom: "5px",
                left: "5px",
                zIndex: 1000,
                pointerEvents: "auto", // Garante que o botão receba eventos
            }}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={faCrosshairs} />
        </Button>
    );
};

class MapCoordinateEditor extends Component {

    map = null;

    state = {
        dragging: false
    }

    handleReady(event) {

        this.map = event.target;

        if (this.props.coordinate.latitude && this.props.coordinate.longitude) {
            this.map.setView([this.props.coordinate.latitude, this.props.coordinate.longitude], 13);
        } else if (!this.props.print) {
            this.map.locate();
        }

    }

    changeCoordinate(latlng) {
        if (this.props.onChangeCoordinate) this.props.onChangeCoordinate({ latitude: latlng.lat, longitude: latlng.lng });
    }

    componentDidUpdate() {
        const { latitude, longitude } = this.props.coordinate;
        if (!this.state.dragging && latitude && longitude)
            this.map.setView([latitude, longitude], this.map.getZoom());
    }

    render() {

        const { latitude, longitude } = this.props.coordinate ?? {};
        return (
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[0, 0]}
                zoom={13}
                whenReady={event => this.handleReady(event)}
                doubleClickZoom={false}
            >
                <CenterMapButton coordinate={this.props.coordinate} />
                <LayersControl position="topright">
                    {/* Camada Vetorial (OpenStreetMap) */}
                    <LayersControl.BaseLayer checked name="Vetorial (OpenStreetMap)">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    {/* Camada de Satélite (ESRI Satellite) */}
                    <LayersControl.BaseLayer name="Satélite (ESRI)">
                        <TileLayer
                            url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>

                {latitude && longitude &&
                    <Marker
                        draggable={!this.props.disabled && this.props.onChangeCoordinate ? true : false}
                        position={[latitude, longitude]}
                        icon={icon}
                        eventHandlers={{
                            dragstart: () => this.setState({ dragging: true }),
                            drag: ({ latlng }) => this.changeCoordinate(latlng),
                            dragend: () => this.setState({ dragging: false })
                        }}>
                    </Marker>}
                <ControlComponent disabled={this.props.disabled} onChangeCoordinate={this.props.onChangeCoordinate} />
            </MapContainer>
        );
    }
}

export default MapCoordinateEditor;
