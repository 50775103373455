import React, { Component } from 'react';
import { LayersControl, MapContainer, Marker, Polygon, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'

const icon = new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] });

const ControlComponent = ({events}) => {
    const map = useMapEvents({
        locationfound: ({ latlng }) => {
            map.setView(latlng, 13);
        }
    });
    if (events?.length) {
        const bounds = L.latLngBounds(events.map(({ latitude, longitude }) => [latitude, longitude]))
        map.fitBounds(bounds);
    } 
}

const CenterMapButton = ({ coordinates }) => {
    const map = useMap();

    const handleClick = event => {
        if (coordinates?.length) {
            event.stopPropagation();
            const bounds = L.latLngBounds(coordinates.map(({ latitude, longitude }) => [latitude, longitude]))
            map.fitBounds(bounds);
        }
    };

    return (
        <Button variant='primary'
            style={{
                position: "absolute",
                bottom: "5px",
                left: "5px",
                zIndex: 1000,
                pointerEvents: "auto", // Garante que o botão receba eventos
            }}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={faCrosshairs} />
        </Button>
    );
};

class MapCluster extends Component {

    handleReady(event) {
        if (this.props.events?.length) {
            const map = event.target;
            const bounds = L.latLngBounds(this.props.events.map(({ latitude, longitude }) => [latitude, longitude]))
            map.fitBounds(bounds);
        }
    }

    render() {
        return (
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[0, 0]}
                zoom={13}
                whenReady={event => this.handleReady(event)}
                doubleClickZoom={false}
            >
                <LayersControl position="topright">
                    {/* Camada Vetorial (OpenStreetMap) */}
                    <LayersControl.BaseLayer checked name="Vetorial (OpenStreetMap)">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    {/* Camada de Satélite (ESRI Satellite) */}
                    <LayersControl.BaseLayer name="Satélite (ESRI)">
                        <TileLayer
                            url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <MarkerClusterGroup chunkedLoading>
                    {this.props.events?.map((event, key) =>
                        <Marker
                            key={key}
                            position={[event.latitude, event.longitude]}
                            title={event.title}
                            icon={icon}>
                                <Popup>
                                    <h3>{event.title}</h3>
                                    {event.body}
                                </Popup>
                        </Marker>)}
                </MarkerClusterGroup>
                <ControlComponent events={this.props.events}/>
                <CenterMapButton coordinates={this.props.events} />
            </MapContainer>
        );
    }
}

export default MapCluster;
