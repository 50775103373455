import React, { Component } from 'react';
import { LayersControl, MapContainer, Marker, Polygon, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import L from 'leaflet';

const icon = new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] });

const ControlComponent = ({ coordinates, onChangeCoordinates, disabled }) => {
    const map = useMapEvents({
        locationfound: ({ latlng }) => {
            map.setView(latlng, 13);
        },
        dblclick: ({ latlng }) => {
            if (onChangeCoordinates && !disabled) onChangeCoordinates([...coordinates, { latitude: latlng.lat, longitude: latlng.lng }]);
        }
    });
    return null;
}

const CenterMapButton = ({ coordinates }) => {
    const map = useMap();

    const handleClick = event => {
        event.stopPropagation();
        const bounds = L.latLngBounds(coordinates.map(({ latitude, longitude }) => [latitude, longitude]))
        map.fitBounds(bounds);
    };

    return (
        <Button variant='primary'
            style={{
                position: "absolute",
                bottom: "5px",
                left: "5px",
                zIndex: 1000,
                pointerEvents: "auto", // Garante que o botão receba eventos
            }}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={faCrosshairs} />
        </Button>
    );
};

class MapPolygonEditor extends Component {

    handleReady(event) {

        const map = event.target;

        if (this.props.coordinates.length) {
            const bounds = L.latLngBounds(this.props.coordinates.map(({ latitude, longitude }) => [latitude, longitude]))
            map.fitBounds(bounds);
        } else if (!this.props.print) {
            map.locate();
        }

    }

    removeCoordinate(index) {
        this.props.onChangeCoordinates(this.props.coordinates.filter((_, i) => i !== index));
    }

    changeCoordinate(index, latlng) {
        this.props.onChangeCoordinates(this.props.coordinates.map((coordinate, i) => i !== index ? coordinate : { ...coordinate, latitude: latlng.lat, longitude: latlng.lng }));
    }

    render() {
        return (
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[0, 0]}
                zoom={13}
                whenReady={event => this.handleReady(event)}
                doubleClickZoom={false}
            >
                <CenterMapButton coordinates={this.props.coordinates} />
                <LayersControl position="topright">
                    {/* Camada Vetorial (OpenStreetMap) */}
                    <LayersControl.BaseLayer checked name="Vetorial (OpenStreetMap)">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    {/* Camada de Satélite (ESRI Satellite) */}
                    <LayersControl.BaseLayer name="Satélite (ESRI)">
                        <TileLayer
                            url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <Polygon positions={this.props.coordinates.map(({ latitude, longitude }) => [latitude, longitude])} />
                {this.props.coordinates.map(({ latitude, longitude }, index) =>
                    <Marker
                        draggable={!this.props.disabled && this.props.onChangeCoordinates? true : false}
                        position={[latitude, longitude]}
                        key={index}
                        icon={icon}
                        eventHandlers={{
                            drag: ({ latlng }) => this.changeCoordinate(index, latlng)
                        }}>
                        {!this.props.disabled && this.props.onChangeCoordinates && <Popup>
                            <Button variant='danger' onClick={() => this.removeCoordinate(index)}>
                                <FontAwesomeIcon icon={faTrash} /> Excluir</Button>
                        </Popup>}
                    </Marker>
                )}
                <ControlComponent disabled={this.props.disabled} coordinates={this.props.coordinates} onChangeCoordinates={this.props.onChangeCoordinates} />
            </MapContainer>
        );
    }
}

export default MapPolygonEditor;
