import { Component } from "react";
import AppContext from "../context/AppContext";
import { Container, ListGroup, ListGroupItem } from "react-bootstrap";
import moment from 'moment';

class HistoricoList extends Component {

    static contextType = AppContext;

    render() {
        return <Container>
            <ListGroup>
                {this.props.historicos?.length ? this.props.historicos.map((historico, key) =>
                    <ListGroup.Item key={key} className="p-2">
                        <b>{moment(historico.datahora).format("DD/MM/YYYY HH:mm")} - {historico.descricao}</b>
                        <p className="m-0"><b>Responsável:</b> {historico.responsavel}</p>
                        <p className="m-0"><b>Observação:</b> {historico.observacao ? historico.observacao : "Sem Observação"}</p>
                    </ListGroup.Item>
                ) :
                    <ListGroupItem>Sem históricos registrados.</ListGroupItem>}
            </ListGroup>
        </Container >;
    }
}

export default HistoricoList;
