import { Button, Container, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCompress, faExpand, faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import AppContext from "../context/AppContext";
import icon from "../images/cedec-cbmpa.png";

class MenuTop extends Component {

    static contextType = AppContext;

    render() {
        return <Navbar expand="lg" className="d-print-none bg-warning" style={{border: 'none'}}>
            <Container fluid>
                <Navbar.Brand className="d-flex align-items-center justify-content-between text-white" style={{width: 180}}>
                    <img src={icon} height={40}/>
                    <Button variant="flush" className="text-white d-md-block d-none" onClick={() => this.props.onToggleMenu()}><FontAwesomeIcon icon={faBars} /></Button>
                </Navbar.Brand>
                <Navbar.Text className="text-white">{this.props.usuario?.nome_exibicao}</Navbar.Text>
            </Container>
        </Navbar>;
    }
}

export default MenuTop;