import { Component } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import logoImage from '../images/cedec-cbmpa.png';
import MapCluster from '../components/MapCluster.jsx';
import AppContext from '../context/AppContext';
import qs from "qs";
import LoginForm from "./LoginForm.jsx";

class Home extends Component {

    static contextType = AppContext;

    state = {
        events: [],

        cobrades: [],
        municipios: [],

        CobradeId: null,
        MunicipioId: null
    }

    pesquisar() {
        const { CobradeId, MunicipioId } = this.state;
        const query = qs.stringify({ CobradeId, MunicipioId });
        this.context.fetchApi("GET", `/home/eventos?${query}`).then(({ data }) => this.setState({ events: data }));
    }

    onChange(data) {
        this.setState(data, () => this.pesquisar());
    }

    componentDidMount() {
        this.context.fetchApi("GET", "/home/cobrades").then(({ data }) => this.setState({ cobrades: data }));
        this.context.fetchApi("GET", "/home/municipios").then(({ data }) => this.setState({ municipios: data }));
        this.pesquisar();
    }

    render() {
        return <Container fluid className="h-100 overflow-auto background-image text-black pb-2">
            <Row className="d-flex h-100">
                <Col className="d-flex flex-column">
                    <Row className="mb-2">
                        <Col>
                            <Form.Label className="small">Desastre</Form.Label>
                            <Form.Select value={this.state.CobradeId} onChange={e => this.onChange({ CobradeId: e.target.value })}>
                                <option></option>
                                {this.state.cobrades.map(({ value, label }, key) => <option key={key} value={value}>{label}</option>)}
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Label className="small">Município</Form.Label>
                            <Form.Select value={this.state.MunicipioId} onChange={e => this.onChange({ MunicipioId: e.target.value })}>
                                <option></option>
                                {this.state.municipios.map(({ value, label }, key) => <option key={key} value={value}>{label}</option>)}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="flex-grow-1">
                        <Col style={{ minHeight: 300 }}>
                            <MapCluster events={this.state.events} />
                        </Col>
                    </Row>
                </Col>
                <Col lg={4} className="h-100 overflow-auto small d-flex flex-column justify-content-between">
                    <Row>
                        <Col>
                            <div className="text-center pt-3"><img src={logoImage} width={80} /></div>
                            <h4 className="text-center">Bem-vindo ao GUARD</h4><hr />
                            <p align="justify">O GUARD (Gestão Unificada de Avaliação de Riscos e Desastres) é o sistema oficial da Coordenadoria Estadual de Defesa Civil destinado ao gerenciamento de pedidos de reconhecimento de situação de emergência e calamidade pública. Desenvolvido para modernizar e agilizar os processos da Defesa Civil, o sistema promove eficiência, transparência e integração entre as coordenadorias estadual e municipais.</p>

                        </Col>
                    </Row>
                    {!this.context.usuario && <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Efetuar Logon</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <LoginForm onSave={credentials => this.context.signIn(credentials)}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>}
                </Col>
            </Row>

        </Container>
    }

}

export default Home;

