import { Component } from "react";
import AppContext from "../context/AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faTimes } from "@fortawesome/free-solid-svg-icons";
import Home from "./Home";

class ChangePassowrd extends Component {

    static contextType = AppContext;

    state = {
        senha0: "",
        senha1: "",
        senha2: "",
    }

    submit(event) {
        event.preventDefault();
        const ColaboradorId = this.context.usuario?.id;
        this.context.fetchApi('POST', `/colaboradores/${ColaboradorId}/password`, this.state).then(() => {            
            this.context.closeModal();
            this.context.addToast({header: "Senha alterada com sucesso!", body: "Realize uma nova entrada para aplicar as alterações."});
            this.context.setContent(<Home />);
            this.context.signOut();            
        });
    }

    render() {
        return <Container>
            <Form onSubmit={e => this.submit(e)}>
                <Row>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Senha Atual</b></Form.Label>
                            <Form.Control required type="password" value={this.state.senha0} onChange={e => this.setState({ senha0: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nova Senha</b></Form.Label>
                            <Form.Control required type="password" value={this.state.senha1} onChange={e => this.setState({ senha1: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Repita a Nova Senha</b></Form.Label>
                            <Form.Control required type="password" value={this.state.senha2} onChange={e => this.setState({ senha2: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col md={12} className="d-flex justify-content-end mt-2">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                        <Button variant="success" className="ms-2" type="submit"><FontAwesomeIcon icon={faKey} /> Alterar Senha</Button>
                    </Col>
                </Row>
            </Form>
        </Container >;
    }
}

export default ChangePassowrd;
