import { faFileDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component, createRef } from "react";
import { Button } from "react-bootstrap";

class FileActionButtons extends Component {

    inputRef = createRef();

    click() {
        this.inputRef.current.click();
    }

    change(event) {
        const file = event.target.files[0];
        if (file) this.props.onChange(file);
    }

    render() {
        return <div className="w-100 d-flex justify-content-center">
            <input type="file" className="d-none" onChange={event => this.change(event)} ref={this.inputRef} />
            {!this.props.disabled &&
                <Button title="Upload" className="" variant="success" onClick={() => this.click()}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            }
            {(this.props.anexo.id || this.props.anexo.arquivo) && <>
                <Button variant="primary" title="Download" className="ms-1">
                    <FontAwesomeIcon icon={faFileDownload} onClick={this.props.onDownload} />
                </Button>
                {!this.props.disabled && this.props.onDelete && <Button title="Excluir" className="ms-1" variant="danger" onClick={this.props.onDelete}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>}
            </>}
        </div>;
    }
}

export default FileActionButtons;