import { Component } from "react";
import { Button, Card, Container, Form, FormGroup, Table } from "react-bootstrap";
import FileActionButtons from "./FileActionButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

class ActionManager extends Component {

    onConfirm() {
        if (this.props.anexos.filter(a => !a.nome).length > 0) {
            window.alert("Adicione todos os arquivos obrigatórios antes de confirmar a ação!");
        } else {
            this.props.onConfirm();
        }
    }

    render() {
        return <Container>
            <Card className={`text-light p-3 mb-3 bg-${this.props.action.variant}`}>{this.props.action.info}</Card>
            {this.props.anexos.length ? <Table responsive size="sm">
                <thead>
                    <tr>
                        <th className="bg-light">Descrição</th>
                        <th className="text-nowrap text-center bg-light">Nome do Arquivo</th>
                        <th className="text-center d-print-none bg-light" style={{ width: 120 }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.anexos.map((anexo, key) =>
                        <tr key={key}>
                            <td className="bg-light">{anexo.descricao}</td>
                            <td className="text-center bg-light">{anexo.nome ?? "(Arquivo sem nome)"}</td>
                            <td className="text-nowrap text-center d-print-none bg-light">
                                <FileActionButtons anexo={anexo} onDownload={() => this.props.onDownload(anexo)} onChange={arquivo => this.props.onChangeAnexo(anexo, { arquivo })} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table> : null}
            <Form.Group className="mb-3">
                <Form.Label><b>Observação</b></Form.Label><br />
                <small>Digite a observação a ser mantida no histórico da solicitação:</small>
                <Form.Control as="textarea" value={this.props.observacao} onChange={event => this.props.onChangeObservacao(event.target.value)} />
            </Form.Group>

            <FormGroup className="d-flex justify-content-between">
                <Button variant="secondary" onClick={this.props.onCancel}>
                    <FontAwesomeIcon icon={Icons.faTimes} /> Cancelar
                </Button>
                <Button variant={this.props.action.variant} onClick={() => this.onConfirm()}>
                    <FontAwesomeIcon icon={Icons[this.props.action.icon]} /> {this.props.action.name}
                </Button>
            </FormGroup>
        </Container>
    }
}

export default ActionManager;