import { Component } from "react";
import { Container, ListGroup, ListGroupItem } from "react-bootstrap";
import logoImage from '../images/cedec-cbmpa.png';
import addPhoto from "../images/addphoto.jpg";
import portaria from '../assets/PORTARIA 194-2024.pdf';
import anexo1 from '../assets/ANEXO I - OFÍCIO DE SOLICITAÇÃO DE AJUDA HUMANITÁRIA.docx';
import anexo2 from '../assets/ANEXO II - FORMULÁRIO DE SOLICITAÇÃO DE AJUDA HUMANITÁRIA.docx';
import anexo3 from '../assets/ANEXO III - PLANO DE TRABALHO.docx';
import anexo4 from '../assets/ANEXO IV - FORMULÁRIO DE PRESTAÇÃO DE CONTAS.docx';
import anexo5 from '../assets/ANEXO V - RELATÓRIO FOTOGRÁFICO DE ASSISTÊNCIA HUMANITÁRIA.doc';
import modelo from '../assets/RELATORIO SOCIAL DE ENTREGA.docx';

class ModelosPage extends Component {

    render() {
        return <Container className="background-image text-black">
            <div className="text-center pt-3"><img src={logoImage} width={200} /></div>
            <h3 className="text-center">Modelos de Documentos para Solicitação de Ajuda Humanitária</h3><hr />
            <ListGroup >
                <ListGroupItem className="d-flex align-items-center">
                <a href={portaria}><img src={addPhoto} height={100} /></a>
                    Portaria nº 194/2024 - Regulamenta o fornecimento de ajuda humanitária pelo Corpo de Bombeiros
                    Militar do Pará e Coordenadoria Estadual de Defesa Civil do Pará
                    aos municípios em situação de anormalidade afetados por desastres nos
                    termos que menciona e dá outras providências.                    
                </ListGroupItem>
                <ListGroupItem className="d-flex align-items-center">
                <a href={anexo1}><img src={addPhoto} height={100} /></a>
                    Ofício de Solicitação de Ajuda Humanitária assinado pelo Prefeito Municipal.                    
                </ListGroupItem>
                <ListGroupItem className="d-flex align-items-center">
                <a href={anexo2}><img src={addPhoto} height={100} /></a>
                    Formulário de Solicitação de Ajuda Humanitária preenchido pela Coordenadoria Municipal de Defesa Civil.                    
                </ListGroupItem>
                <ListGroupItem className="d-flex align-items-center">
                <a href={anexo3}><img src={addPhoto} height={100} /></a>
                    Plano de Trabalho (utilizar um formulário para cada localidade a ser atendida).                  
                </ListGroupItem>
                <ListGroupItem className="d-flex align-items-center">
                <a href={anexo4}><img src={addPhoto} height={100} /></a>
                    Formulário de Prestação de Contas (Deverá ser preenchido um formulário para cada tipo de material).           
                </ListGroupItem>
                <ListGroupItem className="d-flex align-items-center">
                <a href={anexo5}><img src={addPhoto} height={100} /></a>
                    Relatório Fotográfico de Assistência Humanitária.                  
                </ListGroupItem>
                <ListGroupItem className="d-flex align-items-center mb-5">
                <a href={modelo}><img src={addPhoto} height={100} /></a>
                    Relatório Social de Entrega - Relatório sucinto descrevendo as comunidades ou bairros beneficiados,
                    quantidade de famílias beneficiadas e demais dificuldades ou observações referentes a operação,
                    feito pela Coordenadoria Municipal de Proteção e Defesa Civil do município em conjunto com a
                    Secretaria Municipal de Ajuda Social devidamente assinado pelo Coordenador Municipal de Defesa Civil.               
                </ListGroupItem>
            </ListGroup>
        </Container>
    }

}

export default ModelosPage;

