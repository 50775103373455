import { Component, createRef } from "react";
import AppContext from "../context/AppContext";
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFileAlt, faTimes, faTrash, faTruck } from "@fortawesome/free-solid-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import MapPolygonEditor from "../components/MapPolygonEditor";
import MapCoordinateEditor from "../components/MapCoordinateEditor";
import FileUploader from "../components/FileUploader";
import FileActionButtons from "../components/FileActionButtons";
import ImageEditor from "../components/ImageEditor";
import moment from 'moment';
import ActionManager from '../components/ActionManager';

class FormularioForm extends Component {

    static contextType = AppContext;

    formRef = createRef();

    constructor(props) {
        super(props);

        const Anexos = [...(props.data.Anexos ?? [])];

        const Fotografias = [...(props.data.Fotografias ?? [])].map(fotografia => ({
            ...fotografia,
            datahora: moment(fotografia.datahora).format("YYYY-MM-DDTHH:mm")
        }));

        this.state = {

            Pedidos: [],
            Coordenadas: [],
            Questionarios: [],
            status: "Em Edição",
            Historicos: [],
            Actions: [
                {
                    name: "Salvar Rascunho", variant: "info", icon: "faSave",
                    info: "Salvar o formulário atual para revisão e encaminhamento posterior.",
                    status: ["Em Edição", "Rascunho", "Encaminhado para Correção"],
                    documents: [],
                }, {
                    name: "Registrar Ocorrência", variant: "info", icon: "faEdit",
                    info: "Registra os dados atuais apenas como ocorrência sem a solicitação de ajuda humanitária.",
                    status: ["Em Edição", "Rascunho", "Encaminhado para Correção", "Ocorrência Registrada"],
                    documents: []
                }, {
                    name: "Encaminhar para Análise", variant: "primary", icon: "faPaperPlane",
                    info: "Encaminhar o formulário atual para análise da Defesa Civil Estadual.",
                    status: ["Em Edição", "Rascunho", "Encaminhado para Correção"],
                    documents: ["Decreto Municipal", "Publicação", "Ofício de Solicitação"],
                }                   
            ],

            ...this.props.data,

            datahora_ocorrencia: this.props.data.datahora_ocorrencia ? moment(this.props.data.datahora_ocorrencia).format("YYYY-MM-DDTHH:mm") : null,
            datahora_solicitacao: moment(this.props.data.datahora_solicitacao ?? undefined).format("YYYY-MM-DDTHH:mm"),
            datahora_alteracao: moment(this.props.data.datahora_alteracao ?? undefined).format("YYYY-MM-DDTHH:mm"),
            datahora_reconhecimento: this.props.data.datahora_reconhecimento ? moment(this.props.data.datahora_reconhecimento).format("YYYY-MM-DDTHH:mm") : null,
            datahora_autorizacao: this.props.data.datahora_autorizacao ? moment(this.props.data.datahora_autorizacao).format("YYYY-MM-DDTHH:mm") : null,

            Anexos,
            Fotografias,

            FileAnexos: Anexos.map(() => null),
            FileFotografias: Fotografias.map(() => null),

            municipios: [],
            materiais: [],
            questionarios: [],
            cobrades: [],
            statusOptions: [],

            action: {},
            actionName: "",
            showActionModal: false,

            historicoObservacao: ""

        }

    }

    componentDidMount() {

        this.state.Fotografias.forEach((fotografia) => {
            this.context.fetchApi("GET", `/fotografias/${fotografia.id}/arquivo`, null, { responseType: 'blob' })
                .then(({ data }) => {
                    const arquivo = new File([data], "");
                    this.changeFotografia(fotografia, { arquivo });
                });
        });

        this.context.fetchApi("GET", "/municipios/options")
            .then(({ data }) => this.setState({ municipios: data }));

        if (this.state.id) {
            this.context.fetchApi("GET", `/formularios/${this.state.id}/historicos`)
                .then(({ data }) => this.setState({ Historicos: data }));

        }


        this.context.fetchApi("GET", "/status/options")
            .then(({ data }) => this.setState({ statusOptions: data }));

        this.context.fetchApi("GET", "/materiais/options")
            .then(({ data }) => this.setState({ materiais: data }));

        this.context.fetchApi("GET", "/questionarios/options")
            .then(({ data }) => {
                this.setState(({ Questionarios }) => ({
                    questionarios: data,
                    Questionarios: data.map(option => ({
                        QuestionarioId: option.value,
                        divisao: option.divisao,
                        resposta: (Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: option.tipo === "Sim/Não" ? "Não" : "0" }).resposta
                    })),
                }))
            });

        this.context.fetchApi("GET", "/cobrades/options").then(({ data }) => this.setState({ cobrades: data }));

        this.setState({
            MunicipioId: this.props.data.MunicipioId ?? this.context.usuario?.MunicipioId ?? null
        });
    }

    async validate(event, action) {
        event.preventDefault();

        const { Coordenadas, Anexos } = this.state;
        const valid = this.formRef.current.checkValidity();

        if (!valid) return this.formRef.current.reportValidity();

        if (Coordenadas.length < 3) {
            return this.context.addToast({ header: "Erro de Validação", body: "Insira ao menos 3 coordenadas para delimitar a área afetada no mapa (Item 2.2)." })
        }


        if (action) {
            return this.setState(state => {
                const atuais = state.Anexos.map(anexo => anexo.nome && action.documents.includes(anexo.descricao) ? { ...anexo, descricao: `${anexo.descricao} (substituído)` } : anexo);
                const novos = action.documents.filter(document => !atuais.find(atual => atual.descricao === document)).map(document => ({ descricao: document }));
                return {
                    Anexos: [...atuais, ...novos],
                    action,
                    actionName: action.name,
                    showActionModal: true
                }
            });
        } else return;
    }

    async sendData() {

        const {
            id,
            status,
            CobradeId,
            MunicipioId,
            protocolo_s2id,
            decreto_numero,
            decreto_data,
            decreto_tipo,
            datahora_ocorrencia,
            area_descricao,
            causa_descricao,
            dh_descricao,
            dm_descricao,
            da_descricao,
            pu_descricao,
            pr_descricao,
            declaracao_danos,
            declaracao_emergencial,
            declaracao_dificuldades,
            declaracao_mobilizacao_pessoal,
            declaracao_mobilizacao_material,
            declaracao_mobilizacao_financeiro,
            responsavel_nome,
            responsavel_cargo,
            responsavel_email,
            responsavel_contato,
            Coordenadas,
            Questionarios,
            Fotografias,
            Pedidos,
            Anexos,
            actionName,
            historicoObservacao
        } = this.state

        this.props.onSave({
            id,
            status,
            CobradeId,
            MunicipioId,
            protocolo_s2id,
            decreto_numero,
            decreto_data,
            decreto_tipo,
            datahora_ocorrencia,
            area_descricao,
            causa_descricao,
            dh_descricao,
            dm_descricao,
            da_descricao,
            pu_descricao,
            pr_descricao,
            declaracao_danos,
            declaracao_emergencial,
            declaracao_dificuldades,
            declaracao_mobilizacao_pessoal,
            declaracao_mobilizacao_material,
            declaracao_mobilizacao_financeiro,
            responsavel_nome,
            responsavel_cargo,
            responsavel_email,
            responsavel_contato,
            Questionarios,
            Coordenadas,
            Fotografias,
            Pedidos,
            Anexos,
            actionName,
            historicoObservacao
        });

    }

    changeQuestionario(option, resposta) {
        const questionario = this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value);
        this.setState(state => ({ Questionarios: state.Questionarios.map(q => q === questionario ? { ...q, resposta } : q) }));
    }

    getSiblingQuestionario(option, divisao) {
        return this.state.questionarios.find(o => o.label === option.label && o.divisao === divisao) ?? {};
    }

    changeFotografia(fotografia, data) {
        this.setState(state => ({
            Fotografias: state.Fotografias.map(f => f === fotografia ? { ...fotografia, ...data } : f)
        }));
    }

    changePedido(pedido, data) {
        this.setState(state => ({
            Pedidos: state.Pedidos.map(p => p === pedido ? { ...pedido, ...data } : p)
        }));
    }

    getPedidoMaterial(pedido, materialOption) {
        return pedido.Materiais.find(({ MaterialId }) => MaterialId === materialOption.value);
    }

    changePedidoMaterial(pedidoMaterial, data) {
        this.setState(state => ({
            Pedidos: state.Pedidos.map(p => ({
                ...p,
                Materiais: p.Materiais.map(m => m === pedidoMaterial ? { ...m, ...data } : m)
            }))
        }));
    }

    addPedido() {
        this.setState(state => ({
            Pedidos: [
                ...state.Pedidos,
                {
                    Materiais: state.materiais.map(m => ({
                        MaterialId: m.value,
                        desabrigados: "0",
                        desalojados: "0",
                        outros: "0",
                        periodo: "0",
                        qtd_solicitada: "0",
                        qtd_autorizada: "0",
                        qtd_entregue: "0"
                    }))
                }
            ]
        }))
    }

    addFotografia() {
        this.setState(state => ({
            Fotografias: [
                ...state.Fotografias,
                {
                    arquivo: null,
                    titulo: "",
                    datahora: "",
                    descricao: ""
                }
            ]
        }))
    }

    changeAnexo(anexo, data) {
        this.setState(state => ({
            Anexos: state.Anexos.map(a => a === anexo ? {
                ...anexo,
                ...data,
                nome: data.arquivo?.name ?? data.nome ?? a.nome
            } : a)
        }));
    }

    deleteAnexo(anexo) {
        this.setState(state => ({
            Anexos: state.Anexos.filter(a => a !== anexo)
        }))
    }

    fetchArquivo(anexo) {
        return this.context.fetchApi("GET", `/anexos/${anexo.id}/arquivo`, null, { responseType: "blob" }).then(response => {
            console.log(response.data);
            const blob = response.data;
            return new File([blob], anexo.nome ?? "bin");
        });
    }

    downloadArquivo(arquivo) {
        // Criar um URL temporário para o arquivo
        const fileURL = URL.createObjectURL(arquivo);

        // Criar um elemento <a> para iniciar o download
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = arquivo.name || "download";

        // Adicionar o link ao DOM, clicar nele e removê-lo em seguida
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revogar o URL temporário para liberar memória
        URL.revokeObjectURL(fileURL);
    }

    downloadAnexo(anexo) {
        if (anexo.arquivo) {
            this.downloadArquivo(anexo.arquivo);
        } else if (anexo.id) {
            this.fetchArquivo(anexo)
                .then(arquivo => {
                    this.changeAnexo(anexo, { arquivo });
                    this.downloadArquivo(arquivo);
                });
        }
    }

    addAnexos(arquivos) {
        this.setState(state => ({
            Anexos: [
                ...state.Anexos,
                ...arquivos.map(arquivo => ({
                    nome: arquivo.name,
                    descricao: "",
                    arquivo
                }))
            ]
        }))
    }

    render() {

        const numberFormatter = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 4 });
        const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        const formatNumber = number => numberFormatter.format(number);
        const formatCurrency = currency => currencyFormatter.format(currency);

        const qtdDisabled = !this.context.checkPermission("Visualizar Quantidades");
        const editDisabled = !this.context.checkPermission("Editar Formulário");
        const formDisabled = editDisabled && !["Em Edição", "Rascunho", "Encaminhado para Correção"].includes(this.state.status);
        const entregaDisabled = editDisabled && !["Homologação Aprovada", "Entrega Autorizada"].includes(this.state.status);

        const { MunicipioId } = this.context.usuario ?? {};

        const municipios = this.state.municipios.filter(option => !MunicipioId || option.value === MunicipioId || option.value === this.state.MunicipioId);

        const obrigatorios = this.state.action ? this.state.Anexos.filter(a => this.state.action.documents?.includes(a.descricao)) : [];

        return <Container fluid="lg">
            <Form onSubmit={e => this.validate(e)} ref={this.formRef}>
                <h3 className="mt-3"><FontAwesomeIcon icon={faFileAlt} /> Formulário de Solicitação de Ajuda Humanitária</h3>
                <small><span className="required" /> Campos de preenchimento obrigatório</small>
                <hr />
                <section>
                    <h4 className="mt-5">1. Identificação</h4>
                    <Row>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Nº do Formulário</b></Form.Label>
                                <Form.Control disabled value={this.state.id?.toString().padStart(6, '0') ?? "Em Edição"} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Status da Solicitação</b></Form.Label>
                                <Form.Select required disabled={editDisabled} value={this.state.status} onChange={e => this.setState({ status: e.target.value })}>
                                    {this.state.statusOptions.map((status, key) =>
                                        <option key={key}>{status.name}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Protocolo S2ID</b></Form.Label>
                                <Form.Control disabled={formDisabled} value={this.state.protocolo_s2id} onChange={e => this.setState({ protocolo_s2id: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Município</b></Form.Label>
                                <Form.Select
                                    required
                                    disabled={formDisabled}
                                    value={this.state.MunicipioId}
                                    onChange={e => this.setState({ MunicipioId: e.target.value })}>
                                    <option></option>
                                    {municipios.map(({ value, label }, key) => <option key={key} value={value}>{label}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Desastre</b></Form.Label>
                                <Form.Select required disabled={formDisabled} value={this.state.CobradeId} onChange={e => this.setState({ CobradeId: e.target.value })}>
                                    <option></option>
                                    {this.state.cobrades.map(({ value, label }, key) => <option key={key} value={value}>{label}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Data/Hora da Ocorrência</b></Form.Label>
                                <Form.Control required disabled={formDisabled} type="datetime-local" value={this.state.datahora_ocorrencia} onChange={e => this.setState({ datahora_ocorrencia: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Nº do Decreto Municipal</b></Form.Label>
                                <Form.Control required disabled={formDisabled} value={this.state.decreto_numero} onChange={e => this.setState({ decreto_numero: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Data do Decreto</b></Form.Label>
                                <Form.Control required type="date" disabled={formDisabled} value={this.state.decreto_data} onChange={e => this.setState({ decreto_data: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="mb-1">
                            <Form.Group>
                                <Form.Label className="required"><b>Tipo de Decreto</b></Form.Label>
                                <Form.Select required disabled={formDisabled} value={this.state.decreto_tipo} onChange={e => this.setState({ decreto_tipo: e.target.value })}>
                                    <option></option>
                                    <option>Situação de Emergência</option>
                                    <option>Calamidade Pública</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </section>
                <fieldset disabled={formDisabled}>
                    <section>
                        <h4 className="mt-5">2. Área Afetada</h4>
                        <Row>
                            <Col lg={6} className="mb-1">
                                <h5 className="mt-3">2.1 Identificação das Áreas Afetadas</h5>
                                <Table responsive size="sm" className="mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Área</th>
                                            <th className="text-center" style={{ width: 120 }}>Urbana</th>
                                            <th className="text-center" style={{ width: 120 }}>Rural</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Área Afetada Urbana").map((option, key) => {
                                            const optionRural = this.getSiblingQuestionario(option, "Área Afetada Rural");
                                            return <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Check checked={this.state.Questionarios.find(({ QuestionarioId, resposta }) => QuestionarioId === option.value && resposta === "Sim")} onChange={e => this.changeQuestionario(option, e.target.checked ? "Sim" : "Não", "Área Afetada Urbana")} />
                                                </td>
                                                <td className="text-center">
                                                    <Form.Check checked={this.state.Questionarios.find(({ QuestionarioId, resposta }) => QuestionarioId === optionRural.value && resposta === "Sim")} onChange={e => this.changeQuestionario(optionRural, e.target.checked ? "Sim" : "Não", "Área Afetada Rural")} />
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg={6} className="mb-1 d-flex flex-column">
                                <h5 className="mt-3">2.2 Mapa da Área Afetada</h5>
                                <small>Clique duas vezes para adicionar um ponto. Arraste os marcadores para editar o polígono</small>
                                <div className="flex-grow-1" style={{ minHeight: 300 }}>
                                    <MapPolygonEditor disabled={formDisabled} coordinates={this.state.Coordenadas} onChangeCoordinates={Coordenadas => this.setState({ Coordenadas })} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <h5 className="mt-3">2.3 Descrição da Área Afetada</h5>
                                <small>Citar as áreas com população afetada pelo desastre conforme selecionadas no mapa, especificando se urbana ou rural.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.area_descricao} onChange={e => this.setState({ area_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </section>
                    <section>
                        <h4 className="mt-5">3. Causas e Efeitos do Desastres</h4>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <small>Descrever o evento adverso que causou o desastre e as características que demonstraram sua magnitude.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.causa_descricao} onChange={e => this.setState({ causa_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </section>
                    <section>
                        <h4 className="mt-5">4. Danos Humanos, Materiais e Ambientais</h4>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <h5 className="mt-3">4.1 Danos Humanos</h5>
                                <small>Informar a quantidade de mortos, feridos, enfermos, desabrigados, desalojados, desaparecidos e outras pessoas que foram diretamente afetadas pelo desastre, desde que necessitem de auxílio do poder público ou cujos bens materiais tenham sido danificados/destruídos.</small>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Discriminação</th>
                                            <th className="text-center" style={{ width: 120 }}>Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Danos Humanos").map((option, key) =>
                                            <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="1" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {formatNumber(this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Humanos").reduce((total, questionario) => total + (questionario.resposta ? parseInt(questionario.resposta) : 0), 0))}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <h6>4.1.1 Descrição dos Danos Humanos</h6>
                                <small>Registrar informações adicionais e específicas de cada um dos danos humanos citados acima e sua relação direta com os efeitos do desastre.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.dh_descricao} onChange={e => this.setState({ dh_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-1">
                                <h5 className="mt-3">4.2 Danos Materiais</h5>
                                <small>Informar a quantidade de instalações de ensino, saúde, uso comercial ou comunitário, unidades habitacionais ou de obras de infraestrutura danificadas ou destruídas pelo desastre.</small>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Discriminação</th>
                                            <th className="text-center" style={{ width: 120 }}>Danificados</th>
                                            <th className="text-center" style={{ width: 120 }}>Destruídos</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Prejuízo em R$</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Danificados)").map((option, key) => {
                                            const optionDestruido = this.getSiblingQuestionario(option, "Danos Materiais (Destruídos)");
                                            const optionPrejuízo = this.getSiblingQuestionario(option, "Danos Materiais (Prejuízos)");
                                            return <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="1" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="1" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === optionDestruido.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(optionDestruido, e.target.value)} />
                                                </td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="0.01" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === optionPrejuízo.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(optionPrejuízo, e.target.value)} />
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Danificados)").reduce((total, questionario) => total + (questionario.resposta ? parseInt(questionario.resposta) : 0), 0)}
                                            </th>
                                            <th className="text-center">
                                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Destruídos)").reduce((total, questionario) => total + (questionario.resposta ? parseInt(questionario.resposta) : 0), 0)}
                                            </th>
                                            <th className="text-center">
                                                {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Prejuízos)").reduce((total, questionario) => total + (questionario.resposta ? parseFloat(questionario.resposta) : 0), 0))}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <h6>4.2.1 Descrição dos Danos Materiais</h6>
                                <small>Registrar informações adicionais e específicas de cada um dos danos materiais citados acima e sua relação direta com os efeitos do desastre.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.dm_descricao} onChange={e => this.setState({ dm_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-1">
                                <h5 className="mt-3">4.3 Danos Ambientais</h5>
                                <small>Informar as alterações ocorridas no meio ambiente que comprometeram a qualidade ambiental em decorrência direta dos efeitos do desastre.</small>
                                <Table responsive size="sm" className="mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Dano Ambiental</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>% População Atingida</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Danos Ambientais (População Atingida)").map((option, key) => {
                                            return <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="1" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Incêndios</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Área Atingida (em hectares)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Danos Ambientais (Incêndios)").map((option, key) => {
                                            return <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="0.0001" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {formatNumber(this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Ambientais (Incêndios)").reduce((total, questionario) => total + (questionario.resposta ? parseFloat(questionario.resposta) : 0), 0))} ha
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <h6>4.3.1 Descrição dos Danos Ambientais</h6>
                                <small>Registrar informações adicionais e específicas de cada um dos danos ambientais citados acima e sua relação direta com os efeitos do desastre.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.da_descricao} onChange={e => this.setState({ da_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </section>
                    <section>
                        <h4 className="mt-5">5. Prejuízos Econômicos Públicos e Privados</h4>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <h5 className="mt-3">5.1 Prejuízos Econômicos Públicos</h5>
                                <small>Informar o valor estimado de prejuízos econômicos públicos relacionados com os serviços essenciais prejudicados.</small>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Serviço Essencial Prejudicado</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Valor do Prejuízo (em R$)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Públicos").map((option, key) =>
                                            <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="0.01" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Públicos").reduce((total, questionario) => total + (questionario.resposta ? parseFloat(questionario.resposta) : 0), 0))}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <h6>5.1.1 Descrição dos Prejuízos Econômicos Públicos</h6>
                                <small>Descrever como o efeito do desastre causou, diretamente, cada um dos prejuízos econômicos públicos citados acima.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.pu_descricao} onChange={e => this.setState({ pu_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="mb-1">
                                <h5 className="mt-3">5.2 Prejuízos Econômicos Privados</h5>
                                <small>Valor das perdas nos setores da agricultura, pecuária, indústria, comércio e serviços ocorridas em decorrência direta dos efeitos do desastre.</small>
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Setor Econômico</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Valor do Prejuízo (em R$)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Privados").map((option, key) =>
                                            <tr role="button" key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="0.01" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Privados").reduce((total, questionario) => total + (questionario.resposta ? parseFloat(questionario.resposta) : 0), 0))}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <h6>5.2.1 Descrição dos Prejuízos Econômicos Privados</h6>
                                <small>Descrever como o efeito do desastre causou, diretamente, cada um dos prejuízos econômicos privados citados acima.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.pr_descricao} onChange={e => this.setState({ pr_descricao: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>

                    </section>
                    <section>
                        <h4 className="mt-5">6. Caracterização de Situação de Emergência ou Calamidade Pública</h4>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Característica</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Sim / Não</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Caracterização").map((option, key) =>
                                            <tr role="button" key={key}>
                                                <td className="required">{option.label}</td>
                                                <td className="text-center text-nowrap">
                                                    <Form.Check inline name={`Caracterização-${key}`} value="Sim" required type="radio" checked={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "Não" }).resposta === "Sim"} onChange={e => this.changeQuestionario(option, "Sim")} label="Sim" />
                                                    <Form.Check inline name={`Caracterização-${key}`} value="Não" required type="radio" checked={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "Não" }).resposta === "Não"} onChange={e => this.changeQuestionario(option, "Não")} label="Não" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <small>Informe, resumidamente, esses danos e prejuízos:</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.declaracao_danos} onChange={e => this.setState({ declaracao_danos: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>

                    </section>
                    <section>
                        <h4 className="mt-5">7. Informações Relevantes</h4>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Histórico</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Sim / Não</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Histórico").map((option, key) =>
                                            <tr role="button" key={key}>
                                                <td className="required">{option.label}</td>
                                                <td className="text-center text-nowrap">
                                                    <Form.Check inline name={`Histórico-${key}`} value="Sim" required type="radio" checked={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "Não" }).resposta === "Sim"} onChange={e => this.changeQuestionario(option, "Sim")} label="Sim" />
                                                    <Form.Check inline name={`Histórico-${key}`} value="Não" required type="radio" checked={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "Não" }).resposta === "Não"} onChange={e => this.changeQuestionario(option, "Não")} label="Não" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <small>Se este tipo de desastre ocorre repetida e/ou anualmente cite as ações preventivas já desenvolvidas pelo município e explique porque ainda exige ação emergencial.</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.declaracao_emergencial} onChange={e => this.setState({ declaracao_emergencial: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>

                    </section>
                    <section>
                        <h4 className="mt-5">8. Informações sobre a Capacidade Gerencial do Município</h4>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Planejamento Estratégico</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Sim / Não</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Planejamento Estratégico").map((option, key) =>
                                            <tr role="button" key={key}>
                                                <td className="required">{option.label}</td>
                                                <td className="text-center text-nowrap">
                                                    <Form.Check inline name={`Estratégico-${key}`} value="Sim" required type="radio" checked={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "Não" }).resposta === "Sim"} onChange={e => this.changeQuestionario(option, "Sim")} label="Sim" />
                                                    <Form.Check inline name={`Estratégico-${key}`} value="Não" required type="radio" checked={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "Não" }).resposta === "Não"} onChange={e => this.changeQuestionario(option, "Não")} label="Não" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <small>Informe as dificuldades do município para a gestão do desastre:</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.declaracao_dificuldades} onChange={e => this.setState({ declaracao_dificuldades: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </section>
                    <section>
                        <h4 className="mt-5">9. Medidas e Ações em Curso</h4>
                        <small>Indicar as medidas e ações de socorro, assistência e de reabilitação do cenário adotado pelo município.</small>

                        <h5 className="mt-3">9.1 Mobilização e Emprego de Recursos Humanos e Institucionais</h5>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Pessoal Empregado</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Mobilização de Pessoal").map((option, key) =>
                                            <tr key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="1" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {formatNumber(this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização de Pessoal").reduce((total, questionario) => total + (questionario.resposta ? parseInt(questionario.resposta) : 0), 0))}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <small>Descrever outros e/ou detalhar, quando for o caso, o pessoal e equipes já empregados ou mobilizados:</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.declaracao_mobilizacao_pessoal} onChange={e => this.setState({ declaracao_mobilizacao_pessoal: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h5 className="mt-3">9.2 Mobilização e Emprego de Recursos Materiais</h5>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Material/Equipamento Empregado</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Mobilização de Materiais").map((option, key) =>
                                            <tr key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="1" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <small>Descrever e/ou detalhar, quando for o caso, os materiais e equipamentos já empregados ou providenciados:</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.declaracao_mobilizacao_material} onChange={e => this.setState({ declaracao_mobilizacao_material: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <h5 className="mt-3">9.3 Mobilização e Emprego de Recursos Financeiros</h5>
                        <Row>
                            <Col lg={12} className="mb-1">
                                <Table responsive size="sm">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Recurso Empregado</th>
                                            <th className="text-center text-nowrap" style={{ width: 120 }}>Valor em R$</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.questionarios.filter(({ divisao }) => divisao === "Mobilização Financeira").map((option, key) =>
                                            <tr key={key}>
                                                <td>{option.label}</td>
                                                <td className="text-center">
                                                    <Form.Control type="number" min="0" step="0.01" value={(this.state.Questionarios.find(({ QuestionarioId }) => QuestionarioId === option.value) ?? { resposta: "0" }).resposta} onChange={e => this.changeQuestionario(option, e.target.value)} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total</th>
                                            <th>
                                                {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização Financeira").reduce((total, questionario) => total + (questionario.resposta ? parseFloat(questionario.resposta) : 0), 0))}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <small>Descrever e/ou detalhar:</small>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={4} value={this.state.declaracao_mobilizacao_financeiro} onChange={e => this.setState({ declaracao_mobilizacao_financeiro: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </section>
                    <section>
                        <h4 className="mt-5">10. Relatório Fotográfico</h4>
                        <small>Fazer a inclusão de registros fotográficos georreferenciados, indicando detalhadamente sua relação com o desastre.</small>
                        {this.state.Fotografias.map((fotografia, key) =>
                            <Card key={key} className="mb-2">
                                <Card.Header className="d-flex justify-content-between align-items-center">
                                    Situação {(key + 1).toString().padStart(2, '0')}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={8} className="mb-1">
                                            <Row>
                                                <Col lg={12} style={{ height: 300 }}>
                                                    <ImageEditor disabled={formDisabled} file={fotografia.arquivo} onChange={data => this.changeFotografia(fotografia, data)} />
                                                </Col>
                                                <Col lg={8} className="mb-1">
                                                    <Form.Group>
                                                        <Form.Label className="required"><b>Título</b></Form.Label>
                                                        <Form.Control required value={fotografia.titulo} onChange={e => this.changeFotografia(fotografia, { titulo: e.target.value })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="mb-1">
                                                    <Form.Group>
                                                        <Form.Label className="required"><b>Data/Hora</b></Form.Label>
                                                        <Form.Control type="datetime-local" required value={fotografia.datahora} onChange={e => this.changeFotografia(fotografia, { datahora: e.target.value })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <small>Clique na imagem para editar ou adicionar uma nova foto. A data/hora e coordenadas serão atualizadas automaticamente.</small>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={4} className="mb-1">
                                            <Row>
                                                <Col lg={12} style={{ height: 300 }}>
                                                    <MapCoordinateEditor disabled={formDisabled} coordinate={fotografia} onChangeCoordinate={coordinate => this.changeFotografia(fotografia, coordinate)} />
                                                </Col>
                                                <Col lg={12} className="mb-1">
                                                    <Form.Group>
                                                        <Form.Label className="required"><b>Latitude / Longitude</b></Form.Label>
                                                        <InputGroup>
                                                            <Form.Control required type="number" step="0.000001" value={fotografia.latitude} onChange={e => this.changeFotografia(fotografia, { latitude: e.target.value })} />
                                                            <Form.Control required type="number" step="0.000001" value={fotografia.longitude} onChange={e => this.changeFotografia(fotografia, { longitude: e.target.value })} />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <small>Arraste o cursor, dê um duplo clique ou edite manualmente a latitude/longitude.</small>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="mb-1">
                                            <Form.Group>
                                                <Form.Label><b>Descrição da Situação</b></Form.Label>
                                                <small className="d-block">Descrever a situação retratada nas imagens acima e sua relação com o desastre.</small>
                                                <Form.Control as="textarea" rows={4} value={fotografia.descricao} onChange={e => this.changeFotografia(fotografia, { descricao: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-2">
                                        <Button variant="outline-danger" size="sm" onClick={() => window.confirm("Deseja realmente excluir este item?") && this.setState(state => ({ Fotografias: state.Fotografias.filter(f => f !== fotografia) }))}>
                                            <FontAwesomeIcon icon={faTrash} /> Excluir Fotografia
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}
                        <Button className="w-100" onClick={() => this.addFotografia()}>
                            <FontAwesomeIcon icon={faCamera} /> Adicionar Fotografia
                        </Button>
                    </section>
                </fieldset>
                <section>
                    <h4 className="mt-5">11. Plano de Trabalho</h4>
                    <small>Indicar os pedidos de auxílio com os respectivos quantitativos de materiais, discriminados por localidade a ser atendida.</small>
                    {this.state.Pedidos.map((pedido, key) =>
                        <Card key={key} className="mb-2">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                Localidade {(key + 1).toString().padStart(2, '0')}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={8} className="mb-1">
                                        <Form.Group>
                                            <Form.Label className="required"><b>Localidade</b></Form.Label>
                                            <Form.Control required disabled={formDisabled} value={pedido.localidade} onChange={e => this.changePedido(pedido, { localidade: e.target.value })} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} className="mb-1">
                                        <Form.Group>
                                            <Form.Label className="required"><b>Latitude / Longitude</b></Form.Label>
                                            <InputGroup>
                                                <Form.Control required disabled={formDisabled} type="number" step="0.000001" value={pedido.latitude} onChange={e => this.changePedido(pedido, { latitude: e.target.value })} />
                                                <Form.Control required disabled={formDisabled} type="number" step="0.000001" value={pedido.longitude} onChange={e => this.changePedido(pedido, { longitude: e.target.value })} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex align-items-stretch">
                                    <Col lg={12} className="mb-1 d-flex flex-column" style={{ minHeight: 300 }}>
                                        <small>Arraste o cursor, dê um duplo clique ou edite manualmente a latitude/longitude.</small>
                                        <div className="flex-grow-1">
                                            <MapCoordinateEditor disabled={formDisabled} coordinate={pedido} onChangeCoordinate={coord => this.changePedido(pedido, coord)} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className="mb-1">
                                        <Form.Group>
                                            <Table responsive size="sm" className="mb-0">
                                                <thead>
                                                    <tr className="text-small">
                                                        <th>Material</th>
                                                        <th className="text-center" style={{ width: 120 }}>Pessoas Desabrigadas</th>
                                                        <th className="text-center" style={{ width: 120 }}>Pessoas Desalojadas</th>
                                                        <th className="text-center" style={{ width: 120 }}>Outros Afetados</th>
                                                        <th className="text-center" style={{ width: 120 }}>Período (dias)</th>
                                                        <th className="text-center" style={{ width: 120 }}>Quantidade Solicitada</th>
                                                        {!qtdDisabled && <>
                                                            <th className="text-center" style={{ width: 120 }}>Quantidade Autorizada</th>
                                                            <th className="text-center" style={{ width: 120 }}>Quantidade Entregue</th>
                                                        </>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.materiais.map((option, key) => {
                                                        const pedidoMaterial = this.getPedidoMaterial(pedido, option);
                                                        return <tr key={key}>
                                                            <td>{option.label}</td>
                                                            <td><Form.Control disabled={formDisabled} type="number" min="0" step="1" required value={pedidoMaterial.desabrigados} onChange={e => this.changePedidoMaterial(pedidoMaterial, { desabrigados: e.target.value })} /></td>
                                                            <td><Form.Control disabled={formDisabled} type="number" min="0" step="1" required value={pedidoMaterial.desalojados} onChange={e => this.changePedidoMaterial(pedidoMaterial, { desalojados: e.target.value })} /></td>
                                                            <td><Form.Control disabled={formDisabled} type="number" min="0" step="1" required value={pedidoMaterial.outros} onChange={e => this.changePedidoMaterial(pedidoMaterial, { outros: e.target.value })} /></td>
                                                            <td><Form.Control disabled={formDisabled} type="number" min="0" step="1" required value={pedidoMaterial.periodo} onChange={e => this.changePedidoMaterial(pedidoMaterial, { periodo: e.target.value })} /></td>
                                                            <td><Form.Control disabled={formDisabled} type="number" min="0" step="1" required value={pedidoMaterial.qtd_solicitada} onChange={e => this.changePedidoMaterial(pedidoMaterial, { qtd_solicitada: e.target.value })} /></td>
                                                            {!qtdDisabled && <>
                                                                <td><Form.Control disabled={entregaDisabled} type="number" min="0" step="1" required value={pedidoMaterial.qtd_autorizada} onChange={e => this.changePedidoMaterial(pedidoMaterial, { qtd_autorizada: e.target.value })} /></td>
                                                                <td><Form.Control disabled={entregaDisabled} type="number" min="0" step="1" required value={pedidoMaterial.qtd_entregue} onChange={e => this.changePedidoMaterial(pedidoMaterial, { qtd_entregue: e.target.value })} /></td>
                                                            </>}
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end mt-2">
                                    <Button disabled={formDisabled} variant="outline-danger" size="sm" onClick={() => window.confirm("Deseja realmente excluir este item?") && this.setState(state => ({ Pedidos: state.Pedidos.filter(p => p !== pedido) }))}>
                                        <FontAwesomeIcon icon={faTrash} /> Excluir Pedido
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    )}
                    <Button disabled={formDisabled} className="w-100" onClick={() => this.addPedido()}>
                        <FontAwesomeIcon icon={faTruck} /> Adicionar Pedido
                    </Button>
                </section>
                <section>
                    <h4 className="mt-5">12. Anexos</h4>
                    <small>Incluir outros documentos que forem úteis para a análise do pedido de ajuda humanitária.</small>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th className="required">Descrição</th>
                                <th className="text-nowrap text-center">Nome do Arquivo</th>
                                <th className="text-center d-print-none" style={{ width: 120 }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Anexos.map((anexo, key) =>
                                <tr key={key}>
                                    <td><Form.Control style={{ borderColor: anexo.descricao ? '' : 'red' }} required disabled={formDisabled} placeholder="Digite uma descrição" value={anexo.descricao} onChange={event => this.changeAnexo(anexo, { descricao: event.target.value })} /></td>
                                    <td className="text-center">{anexo.nome ?? "(Arquivo sem nome)"}</td>
                                    <td className="text-nowrap text-center d-print-none">
                                        <FileActionButtons disabled={(anexo.id && anexo.nome) || formDisabled} anexo={anexo} onDownload={() => this.downloadAnexo(anexo)} onDelete={() => window.confirm("Deseja realmente excluir este item?") && this.deleteAnexo(anexo)} onChange={arquivo => this.changeAnexo(anexo, { arquivo })} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <FileUploader disabled={formDisabled} onChange={arquivos => this.addAnexos(arquivos)} />
                </section>
                <section>
                    <h4 className="mt-5">13. Registro de Datas</h4>
                    <Row>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Elaboração</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_elaboracao ? moment(this.state.datahora_elaboracao).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Análise</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_analise ? moment(this.state.datahora_analise).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Homologação</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_homologacao ? moment(this.state.datahora_homologacao).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Autorização</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_autorizacao ? moment(this.state.datahora_autorizacao).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Entrega</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_entrega ? moment(this.state.datahora_entrega).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Prestação de Contas</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_prestacao ? moment(this.state.datahora_prestacao).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data da Finalização</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_finalizacao ? moment(this.state.datahora_finalizacao).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="mb-1">
                            <Form.Group>
                                <Form.Label><b>Data do Cancelamento</b></Form.Label>
                                <Form.Control disabled value={this.state.datahora_cancelamento ? moment(this.state.datahora_cancelamento).format("DD/MM/YYYY HH:mm") : ""} />
                            </Form.Group>
                        </Col>
                    </Row>
                </section>
                <section>
                    <h4 className="mt-5">14. Histórico da Solicitação</h4>
                    <ListGroup>
                        {this.state.Historicos?.length ? this.state.Historicos.map((historico, key) =>
                            <ListGroup.Item key={key} className="p-2">
                                <b>{moment(historico.datahora).format("DD/MM/YYYY HH:mm")} - {historico.descricao}</b>
                                <p className="m-0"><b>Responsável:</b> {historico.responsavel}</p>
                                <p className="m-0"><b>Observação:</b> {historico.observacao ? historico.observacao : "Sem Observação"}</p>
                            </ListGroup.Item>
                        ) :
                            <ListGroup.Item>Sem históricos registrados.</ListGroup.Item>}
                    </ListGroup>
                </section>
                <Row className="my-5">
                    <Col md={12} className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button className="me-auto flex-fill" variant="secondary" onClick={() => this.props.onCancel()}><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                        {this.state.Actions.filter(action => this.context.checkPermission(action.name)).map(action =>
                            <Button className="flex-fill" variant={action.variant} onClick={event => this.validate(event, action)}>
                                <FontAwesomeIcon icon={Icons[action.icon]} /> {action.name}
                            </Button>
                        )}
                    </Col>
                </Row>
                <Modal show={this.state.showActionModal} onHide={() => this.setState({ showActionModal: false, action: {}, actionName: "" })} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Executar Ação</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <ActionManager
                            anexos={obrigatorios}
                            observacao={this.state.historicoObservacao}
                            onChangeObservacao={value => this.setState({ historicoObservacao: value })}
                            action={this.state.action}
                            onDownload={anexo => this.downloadAnexo(anexo)}
                            onConfirm={() => this.sendData()}
                            onChangeAnexo={(anexo, data) => this.changeAnexo(anexo, data)}
                            onCancel={() => this.setState({ showActionModal: false, action: {}, actionName: "" })}
                        />
                    </Modal.Body>
                </Modal>
                <Button type="submit" style={{ opacity: 0, position: "absolute", top: 0 }} />
            </Form>
        </Container>;
    }
}

export default FormularioForm;
